<template>
    <v-container class="fill-height" max-width="600px">
        <v-row align="center" justify="center">
            <v-col cols="12" sm="8" md="4">
                <v-card class="elevation-10">
                    <v-toolbar color="primary" dark flat>
                        <v-toolbar-title>Login</v-toolbar-title>
                    </v-toolbar>
                    <v-card-text>
                        <v-form ref="form" v-model="valid" lazy-validation>
                            <v-text-field
                                v-model="username"
                                label="MC Login"
                                name="login"
                                prepend-icon="mdi-account"
                                type="text"
                                :rules="$rules.required"
                            />
                            <v-text-field
                                v-model="password"
                                type="password"
                                id="password"
                                label="Password"
                                name="password"
                                prepend-icon="mdi-lock"
                                :rules="$rules.required"
                                @keyup.enter="login"
                            />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <template v-if="!user.username">
                            <v-btn
                                color="primary"
                                :disabled="!valid || loading"
                                :loading="loading"
                                @click="login"
                                block
                                text
                            >Login</v-btn>
                        </template>
                        <template v-else>
                            <v-spacer />
                            <v-icon
                                color="success"
                                large
                            >mdi-check-circle-outline</v-icon>
                            <v-spacer />
                        </template>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import { computed, ref } from '@vue/composition-api';

export default {
    setup(_, { root, refs }) {

        const store = root.$store;

        const valid = ref(false);
        const username = ref('');
        const password = ref('');
        const loading = ref(false);

        const user = computed(() => store.getters['user']);

        const login = async () => {
            if (refs.form.validate()) {
                loading.value = true;

                const authData = {
                    username: username.value,
                    password: password.value,
                    from: root.$route.query,
                };

                await store.dispatch('login', authData);
                
                loading.value = false;
            }
        };

        return {
            valid,
            username,
            password,
            login,
            loading,
            user
        };
    }
}
</script>