import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{staticClass:"fill-height",attrs:{"max-width":"600px"}},[_c(VRow,{attrs:{"align":"center","justify":"center"}},[_c(VCol,{attrs:{"cols":"12","sm":"8","md":"4"}},[_c(VCard,{staticClass:"elevation-10"},[_c(VToolbar,{attrs:{"color":"primary","dark":"","flat":""}},[_c(VToolbarTitle,[_vm._v("Login")])],1),_c(VCardText,[_c(VForm,{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VTextField,{attrs:{"label":"MC Login","name":"login","prepend-icon":"mdi-account","type":"text","rules":_vm.$rules.required},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}}),_c(VTextField,{attrs:{"type":"password","id":"password","label":"Password","name":"password","prepend-icon":"mdi-lock","rules":_vm.$rules.required},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.login.apply(null, arguments)}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1)],1),_c(VCardActions,[(!_vm.user.username)?[_c(VBtn,{attrs:{"color":"primary","disabled":!_vm.valid || _vm.loading,"loading":_vm.loading,"block":"","text":""},on:{"click":_vm.login}},[_vm._v("Login")])]:[_c(VSpacer),_c(VIcon,{attrs:{"color":"success","large":""}},[_vm._v("mdi-check-circle-outline")]),_c(VSpacer)]],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }